import { useQuery, useMutation } from "@tanstack/react-query";
import storage from "@l/storage";
import client from "@/lib/axios-client";

const loan = (loanId: string | undefined, isAdmin: boolean) => {
  const { partner } = storage?.getUser() || {};
  const { id: partnerId } = partner || {};

  if (isAdmin) {
    return client.get(`/v1/origination/loans/${loanId}`);
  } else {
    return client.get(`/v1/origination/partners/${partnerId}/loans/${loanId}`);
  }
};

const loanAssetMedia = (carId: string) => {
  const params = new URLSearchParams({
    car_id: carId,
  });

  return client.get("/v1/inventory/car_media", { params });
};

const basicInspection = (carId: string) => {
  const params = new URLSearchParams({
    car_id: carId,
  });

  return client.get("/v1/inspection", { params });
};

const getPPI = (cardId: string) => {
  return client.get(`/v1/inspection?car_id=${cardId}&type=warranty`);
};

const detailedInspection = (carId: string) => {
  return client.get(
    `/v1/inventory/car/${carId}/inspection/marketplace?full=true`
  );
};

const loanDocument = (loanId: string | undefined) => {
  const params = new URLSearchParams({
    loan_id: loanId ?? "",
  });

  return client.get("/v1/origination/documents", { params });
};

const documentByLoan = (loanId: string | undefined) => {
  const params = new URLSearchParams({
    loan_id: loanId ?? "",
  });
  return client.get("/v1/origination/loan/documents", { params });
};

const makeOffer = (data: IMakeOffer) => {
  return client.post("/v1/origination/offers", data);
};

const deleteOffer = (offer_id: string) => {
  return client.delete(`/v1/origination/offers/${offer_id}`);
};

const getAdditionalDocuments = (country: string) => {
  const params = new URLSearchParams({
    country: country || "NG",
  });
  return client.get("/v1/origination/additionalDocuments", { params });
};

const requestEquity = (data: IRequestEquity) => {
  return client.post("/v1/origination/equity", data);
};

export const useLoan = (loanId: string | undefined, isAdmin: boolean) => {
  return useQuery(["loan", loanId], () => loan(loanId, isAdmin), {
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });
};

export const useLoanAssetMedia = (carId: string, enabled: boolean) => {
  return useQuery(["loanAssetMedia", carId], () => loanAssetMedia(carId), {
    enabled,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

export const useBasicInspection = (carId: string, enabled: boolean) => {
  return useQuery(["basicInspection", carId], () => basicInspection(carId), {
    enabled,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

export const useDetailedInspection = (carId: string, enabled: boolean) => {
  return useQuery(
    ["detailedInspection", carId],
    () => detailedInspection(carId),
    {
      enabled,
      retry: false,
      refetchInterval: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
};

export const useGetPPI = (carId: string, enabled: boolean) => {
  return useQuery(["getPPI", carId], () => getPPI(carId), {
    enabled,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

export const useLoanDocument = (
  loanId: string | undefined,
  enabled: boolean
) => {
  return useQuery(["loanDocument", loanId], () => loanDocument(loanId), {
    enabled,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

export const useDocumentByLoan = (
  loanId: string | undefined,
  enabled: boolean
) => {
  return useQuery(["loan_Document", loanId], () => documentByLoan(loanId), {
    enabled,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

export const useAdditionalDocuments = (country: string, enabled: boolean) => {
  return useQuery(
    ["AdditionalDocuments", country],
    () => getAdditionalDocuments(country),
    {
      enabled,
      retry: false,
      refetchInterval: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
};

export const useMakeOffer = () => {
  return useMutation(makeOffer);
};

export const useDeleteOffer = () => {
  return useMutation(deleteOffer);
};

export const useRequestEntity = () => {
  return useMutation(requestEquity);
};

const addAdditionalDocument = (data: AdditionalDocumentPayload) => {
  return client.post("/v1/origination/additionalDocuments", data);
};

const requestDocuments = (data: RequestDocuments) => {
  return client.post("/v1/origination/documents/request", data);
};

export const useRequestDocument = () => {
  return useMutation(requestDocuments);
};

export const useAddAdditionalDocument = () => {
  return useMutation(addAdditionalDocument);
};

export const carSearchById = (params: URLSearchParams) => {
  return client.get(
    "/v1/inventory/car?exclude_sold_cars=true&exclude_category_name=logbook",
    { params }
  );
};

export const getCarList = (params: URLSearchParams) => {
  return client.get("/v1/inventory/car", { params });
};

export const getLoanStatistics = (userId: string, country: string) => {
  return client.get(
    `/v1/origination/dig/loans/statistics?user_id=${userId}&country=${country}`
  );
};

export const allFranchise = (country: string) => {
  const params = new URLSearchParams({
    country_code: country,
  });

  return client.get("/v1/franchise", { params });
};

export const carMake = (country: string, pageSize: any) => {
  const params = new URLSearchParams({
    country_code: country,
    page_size: pageSize,
  });

  return client.get("/v1/inventory/make?has_inventory=true", { params });
};

export const dealerSearch = (
  country: string,
  pageSize: any,
  accountManagerId: string,
  dsaId: string
) => {
  const isAccountManager = storage.utilities.isAccountManager();
  const isDealerDsa = storage.utilities.isDealerDsa();

  const params = new URLSearchParams({
    country_code: country,
    page_size: pageSize,
    account_manager_id: isAccountManager ? accountManagerId : "",
    dsa_id: isDealerDsa ? dsaId : "",
  });

  return client.get(`/v1/franchise`, { params });
};

export const getState = (countryId: string) => {
  const params = new URLSearchParams({
    country_id: countryId,
  });

  return client.get("/v1/state?page_size=100", { params });
};

export const carModel = (country: any, id: any, pageSize: any) => {
  const params = new URLSearchParams({
    country_code: country,
    make_id: id,
    page_size: pageSize,
  });

  return client.get("/v1/inventory/model", { params });
};

export const bodyType = (pageSize: number) => {
  return client.get(`/v1/inventory/body_type?page_size=${pageSize}`);
};

export const getProduct = (country: any) => {
  const params = new URLSearchParams({
    country,
  });

  return client.get("/v1/origination/product", { params });
};

export const getProductData = (id: any) => {
  return client.get(`/v1/origination/product/${id}`);
};

export const getDealerProduct = (country: any) => {
  const params = new URLSearchParams({
    country,
  });

  return client.get("/v1/dealer/products", { params });
};

export const getDealerProductData = (id: any, isEnhancement: string) => {
  const params = new URLSearchParams({
    ...(isEnhancement && { is_enhancement: isEnhancement }),
  });
  return client.get(`/v1/dealer/products/${id}`, { params });
};

export const getSimulationConfig = (loanId: any) => {
  return client.get(
    `/v2/origination/loans/${loanId}/offer-simulations/configs`
  );
};

export const getConfigData = (id: any) => {
  return client.get(`/v2/origination/loan/document-config/${id}`);
};

export const getDealerConfigData = (id: any, is_additional_document: any) => {
  const params = new URLSearchParams({
    is_additional_document,
  });
  return client.get(`/v1/dealer/${id}/required-document-upload`, { params });
};

export const fetchCountryId = (coutryCode: any) => {
  return client.get(`/v1/country/${coutryCode}`);
};

export const getStates = (coutryId: any) => {
  return client.get(`/v1/state?country_id=${coutryId}&page_size=1000`);
};

export const getCities = (state: any) => {
  return client.get(`/v1/city?state_id=${state}&page_size=1000`);
};

export const getBanks = (country: any) => {
  return client.get(`/v1/origination/banks?country=${country}`);
};

export const getCountries = () => {
  return client.get(`/v1/country?page_size=1000`);
};

export const getVehicleColors = () => {
  return client.get(`/v1/inventory/marketplace/colors`);
};

export const getAboutUs = (country: any) => {
  return client.get(`/v1/origination/sources?country=${country}`);
};

export const getFranchise = (country: any) => {
  return client.get(
    `/v1/franchise?current_page=1&page_size=10000&country=${country}`
  );
};

export const getDealerCars = (country: any, id: any) => {
  return client.get(
    `/v1/inventory/car?page_size=10000&country=${country}&franchise_id=${id}`
  );
};

export const getAllCarsByMakeId = (country: any) => {
  return client.get(`/v1/inventory/car?page_size=1000&country=${country}`);
};

export const getAdminCarById = (id: string) => {
  return client.get(`/v1/inventory/admin/car/${id}`);
};

export const loanDetails = (loanId: string, country: any) => {
  const params = new URLSearchParams({
    country,
  });
  return client.get(`/v1/origination/loans/${loanId}`, { params });
};

export const createLoans = async (payload: any) => {
  return await client.post("/v2/origination/loan", payload);
};

export const updateLoans = async (payload: any, loanId: any) => {
  return await client.put(`/v2/origination/loan/${loanId}`, payload);
};

export const createLoanDocument = async (payload: {
  file: Blob;
  public: string;
}) => {
  const formData = new FormData();
  Object.entries(payload).forEach(([key, value]) => {
    formData.append(key, value);
  });
  return await client.post("/document/upload", formData);
};

export const createDocuments = async (payload: {
  loan_id: string;
  documents: Record<string, string>[];
  data: Record<string, string>[];
}) => {
  return await client.post(
    `/v2/origination/documents/CreateDocuments`,
    payload
  );
};

export const uploadSignedDocuments = async (
  loanId: string,
  payload: {
    loan_id: string;
    offer_id: string;
    documents: Record<string, string>[];
  }
) => {
  return await client.post(
    `/v2/origination/loan/${loanId}/loanDocuments`,
    payload
  );
};
export const uploadSignedDocumentsDF = async (
  payload: {
    loan_id: string;
    offer_id: string;
    documents: Record<string, string>[];
  }
) => {
  return await client.post(
    `/v1/dealer/contract-upload`,
    payload
  );
};

export const uploadDealerSignedDocuments = async (payload: {
  loan_id: string;
  offer_id: string;
  documents: Record<string, string>[];
}) => {
  return await client.post(`/v1/dealer/contract-upload`, payload);
};

export const uploadMasterAgreementDocuments = async (payload: {
  loan_limit_request_id: string;
  loan_limit_id: string;
  documents: Record<string, string>[];
}) => {
  return await client.post(`/v1/dealer/signed-limit-request-doc`, payload);
};

export const createDealerDocuments = async (payload: {
  loan_id: string;
  dealer_id: string;
  documents: Record<string, string>[];
  vin: string;
}) => {
  return await client.post(`/v1/dealer/uploads`, payload);
};

export const createUploadAdditionalDocuments = async (payload: {
  loan_id: string;
  documents: Record<string, string>[];
}) => {
  return await client.post(`/v1/dealer/upload-additional-documents`, payload);
};
