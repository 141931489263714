import { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import tw from "tailwind-styled-components";
import storage from "@l/storage";
import Item from "@b/aside/item";
import authState from "../states/auth";
import styled from "styled-components";
import { device } from "@/utils/device";
import { getCountryFlag } from "@/utils/getCountryFlag";
import MobileNav from "@/layouts/application-layouts/MobileNavView";
import { Badge, Select, Dropdown, message } from "antd";
import NotificationView from "@/layouts/application-layouts/Notification";
import client from "@/lib/axios-client";
import { useTranslation } from "react-i18next";
import type { MenuProps } from "antd";
import { ChevronDown, HelpCircle, NewMenuIcon } from "@/assets/svgs";
import { determineRole } from "@/utils/determine-role";
import { Loader } from "@/pages/auth/styles/loader";

interface IActivePage {
  currentPage: number;
  pageSize: number;
}

const StyledContainer = styled.div`
  background: #fff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 48px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1005;
  @media ${device.laptop} {
    padding: 0 16px;
    height: 56px;
  }
  @media ${device.tablet} {
    padding: 0 16px;
    height: 56px;
  }
  @media ${device.mobileL} {
    padding: 0 16px;
    height: 56px;
  }

  .top-menu-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
  }
`;

const MenuLayout = styled.div`
  display: flex;
  align-items: center;
  /* gap: 6rem; */
  @media ${device.tablet} {
    gap: 14px;
  }
  @media ${device.mobileL} {
    gap: 14px;
  }
  .web-logo {
    display: block;
    @media ${device.mobileL} {
      display: none;
    }
  }
  .mobile-logo {
    display: none;
    @media ${device.tablet} {
      display: none;
    }
    @media ${device.mobileL} {
      display: block;
    }
  }
`;

const LogoutContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  .web-logout {
    display: block;
    @media ${device.laptop} {
      display: none;
    }
    @media ${device.tablet} {
      display: none;
    }
    @media ${device.mobileL} {
      display: none;
    }
  }
  .mobile-logout,
  .menu-mobile {
    display: none;
    @media ${device.laptop} {
      display: block;
    }
    @media ${device.tablet} {
      display: block;
    }
    @media ${device.mobileL} {
      display: block;
    }
  }
  .notification-container {
    position: relative;
    display: flex;
    align-items: center;
    gap: 24px;
  }
  .ant-select {
    height: 40px;
    width: 102.48px;
    @media ${device.mobileL} {
      width: auto;
      height: auto;
    }
    .ant-select-selector {
      height: 40px;
      border-radius: 2px;
      background: #f8fafc;
      align-items: center;
      color: rgb(48, 52, 94);
      font-size: 16px;
      font-weight: 600;
      @media ${device.mobileL} {
        height: auto;
      }
    }
  }
`;

const MoreButton = styled.div`
  padding: 20px 14px;
  color: #30345e;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
  @media ${device.mobileL} {
    padding: 16px 12px;
  }
`;

const Items = tw.div`mx-3 overflow-hidden flex`;

const Aside = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation("navigation");
  const isFranchise = storage.utilities.isFranchise();
  const isAccountManager = storage.utilities.isAccountManager();
  const isDealerDSA = storage.utilities.isDealerDsa();
  const isAdmin = storage.utilities.isAdmin();
  const country = storage.getCountry();
  const user = storage.getUser();
  const isAssumedUser = storage.getAssumedUser();
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const setLogin = authState((state: IAuthState) => state.setLogin);
  const [openMobile, setOpenMobile] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notifications, setNotifications] = useState(
    {} as INotificationsResponse
  );
  const [inAppNotifications, setInAppNotifications] = useState(
    {} as INotificationsResponse
  );
  const [lang, setLang] = useState("");
  const [isMobileView, setIsMobileView] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<IActivePage>({
    currentPage: 1,
    pageSize: 5,
  });
  const [inAppActivePage, setInAppActivePage] = useState<IActivePage>({
    currentPage: 1,
    pageSize: 5,
  });
  const [loading, setLoading] = useState(false);

  const checkMobileView = () => {
    const breakpoint: number = 1024;
    setIsMobileView(window.innerWidth <= breakpoint);
  };

  useEffect(() => {
    checkMobileView();
    window.addEventListener("resize", checkMobileView);

    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);

  const icons = {
    chevronDown: <ChevronDown />,
    help: <HelpCircle />,
  };

  const items: MenuProps["items"] = [
    {
      label: (
        <Link
          to={"/loan-calculator"}
          style={{ color: "#30345e", fontWeight: "600" }}
        >
          {t("loan-calculator").toUpperCase()}
        </Link>
      ),
      key: "0",
    },
    {
      type: "divider",
    },
    {
      label: (
        <Link
          to={"/prequalification"}
          style={{ color: "#30345e", fontWeight: "600" }}
        >
          {t("prequalification").toUpperCase()}
        </Link>
      ),
      key: "1",
    },
  ];

  const menus = [
    {
      title: t("dashboard"),
      path: "/dashboard",
      new: false,
    },
    {
      title: t("applications"),
      path: "/applications",
      new: false,
    },
    {
      title: t("loan-calculator"),
      path: "/loan-calculator",
      new: false,
    },
    {
      title: t("prequalification"),
      path: "/prequalification",
      new: false,
    },
  ];

  const otherRoleMenus = [
    {
      title: t("dashboard"),
      path: "/dashboard",
      new: false,
    },
    {
      title: t("applications"),
      path: "/applications",
      new: false,
    },
    {
      title: t("inventory"),
      path: "/inventory",
      new: false,
    },
    {
      title: t("loan-calculator"),
      path: "/loan-calculator",
      new: false,
    },
    {
      title: t("prequalification"),
      path: "/prequalification",
      new: false,
    },
  ];

  const otherRoleMenusDF = [
    {
      title: t("dashboard"),
      path: "/dashboard",
      new: false,
    },
    {
      title: t("applications"),
      path: "/applications",
      new: false,
    },
    {
      title: t("financing"),
      path: "/financing",
      new: true,
    },
    {
      title: t("inventory"),
      path: "/inventory",
      new: false,
    },
    {
      title: t("loan-calculator"),
      path: "/loan-calculator",
      new: false,
    },
    {
      title: t("prequalification"),
      path: "/prequalification",
      new: false,
    },
  ];

  const franchiseMenus = [
    {
      title: t("dashboard"),
      path: "/dealer/dashboard",
      new: false,
    },
    {
      title: t("applications"),
      path: "/dealer/applications",
      new: false,
    },
    {
      title: t("my-financing"),
      path: "/dealer/financing",
      new: false,
    },
    // {
    //   title: "best deals",
    //   path: "/dealer/best-deals",
    //   new: true,
    // },
    {
      title: t("inventory"),
      path: "/dealer/inventory",
      new: false,
    },
  ];

  const adminMenus = [
    {
      title: "Manage Users",
      path: "/manage/users",
      new: false,
    },
  ];

  const dealerFinanceCountries = ["NG", "KE", "UG"];
  let containsDealerFinanceCountry = false;

  for (const substring of dealerFinanceCountries) {
    if (country?.includes(substring)) {
      containsDealerFinanceCountry = true;
      break;
    }
  }

  let targetMenus;

  if (isAdmin) {
    targetMenus = adminMenus;
  } else if (isFranchise) {
    targetMenus = franchiseMenus;
  } else if (isAccountManager || isDealerDSA) {
    targetMenus = containsDealerFinanceCountry
      ? otherRoleMenusDF
      : otherRoleMenus;
  } else {
    targetMenus = menus;
  }

  const navMenus = targetMenus;

  const { isLoading: loadingNotification } = useQuery({
    queryKey: ["fetch_notifications", country, activePage],
    queryFn: () => {
      const params = new URLSearchParams({
        dealer_id: user?.company?.id,
        page_size: activePage?.pageSize?.toString(),
        current_page: activePage?.currentPage?.toString(),
      });
      return client.get(`/v1/dealer/${user.company.id}/notifications`, {
        params,
      });
    },
    onSuccess: (res: any) => {
      const parsedNotifications: INotificationsResponse = JSON.parse(res?.data);
      setNotifications(parsedNotifications);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: InAppLoadNotification } = useQuery({
    queryKey: ["fetch_in_app_notifications", country, inAppActivePage],
    queryFn: () => {
      const params = new URLSearchParams({
        user_id: user?.id,
        page_size: inAppActivePage?.pageSize?.toString(),
        current_page: inAppActivePage?.currentPage?.toString(),
      });
      return client.get(`/v2/origination/${user?.id}/notifications`, {
        params,
      });
    },
    onSuccess: (res: any) => {
      const parsedNotifications: INotificationsResponse = JSON.parse(res?.data);
      setInAppNotifications(parsedNotifications);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const totalNotification = () => {
    const filteredDealerNotif = notifications?.notifications?.filter(
      (item) => item?.isRead === false
    )?.length;

    const notificationNumbers = isAccountManager
      ? inAppNotifications?.pagination?.total
      : filteredDealerNotif + inAppNotifications?.pagination?.total;

    return notificationNumbers || 0;
  };

  const handleSignOut = () => {
    queryClient.clear();
    storage.clearAll();
    setLogin(false);
    navigate("/auth/sign-in", { replace: true });
  };

  const showMobileDrawer = () => {
    setOpenMobile(true);
  };

  const onCloseMobile = () => {
    setOpenMobile(false);
  };

  const handleCountryLanguage = useCallback(() => {
    let targetLanguage = "";

    switch (country) {
      case "CI":
      case "SN":
        targetLanguage = "fr";
        break;
      case "NG":
      case "GH":
      case "KE":
      case "UG":
        targetLanguage = "en";
        break;
      case "EG":
        targetLanguage = "ar";
        break;
      default:
        break;
    }

    i18n.changeLanguage(targetLanguage);
    setLang(targetLanguage);
    window.localStorage.setItem("storedLang", targetLanguage);
  }, [i18n, country]);

  const handleLanguageChange = (value: string) => {
    const queryParams = new URLSearchParams(location.search);

    if (value) {
      queryParams.set("lang", value);
      i18n.changeLanguage(value);
      setLang(value);
      window.localStorage.setItem("storedLang", value);

      const path = `${location.pathname}?${queryParams.toString()}`;
      navigate(path);
    }
  };

  useEffect(() => {
    const getStoredLang: any = window.localStorage.getItem("storedLang");
    if (getStoredLang) {
      i18n.changeLanguage(getStoredLang);
      setLang(getStoredLang);
    } else {
      handleCountryLanguage();
    }
  }, [i18n, handleCountryLanguage]);

  const handleRevertUser = async () => {
    setLoading(true);
    try {
      const response: any = await client.post(`/v1/auth/revert-user`, {});
      if (response) {
        const parsedResponse = JSON.parse(response?.data);
        const token = parsedResponse?.token;
        const user = parsedResponse?.user;
        const roles = parsedResponse?.authority?.roles;
        const permissions = parsedResponse?.authority?.permissions;
        const country = parsedResponse?.user?.country;

        const role = determineRole(roles);

        if (role) storage.setRole(role);

        storage.setToken(token);
        storage.setUser(user);
        storage.setRoles(roles);
        storage.setPermissions(permissions);
        storage.setCountry(country);
        storage.clearAssumedUser();
        storage.clearProxyUserToken();

        navigate("/manage/users", { replace: true });
        setLoading(false);
        message.success("User role reverted succesfully");
      }
    } catch (error: any) {
      const errorResp = JSON.parse(error?.response?.data)?.message;
      message.error(errorResp);
      setLoading(false);
    }
  };

  return (
    <>
      <StyledContainer>
        <div className="top-menu-container">
          <MenuLayout>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <img
                src="https://media.autochek.africa/file/publicAssets/logo-horizontal.svg"
                alt="autochek-logo"
                width="129px"
                height="30.58px"
                className="web-logo"
              />
              <img
                src="https://media.autochek.africa/file/publicAssets/logo-icon.svg"
                alt="autochek-logo"
                width="33.07px"
                height="34.13px"
                className="mobile-logo"
              />
              <img
                src={getCountryFlag(country)}
                alt="flag"
                style={{ width: "21px", height: "14px" }}
              />
            </div>
          </MenuLayout>

          <div>
            {!isMobileView && (
              <Items>
                {navMenus.map((menu, index) => (
                  <Item $to={menu.path} key={navMenus.indexOf(menu)}>
                    <div className="relative">
                      {menu.new === true && (
                        <div className="absolute -right-4 -top-4">
                          <NewMenuIcon />
                        </div>
                      )}
                      {menu.title.toUpperCase()}
                    </div>
                  </Item>
                ))}
                {isFranchise && (
                  <Dropdown menu={{ items }}>
                    <MoreButton>
                      {t("more")} {icons.chevronDown}
                    </MoreButton>
                  </Dropdown>
                )}
              </Items>
            )}
          </div>

          <LogoutContainer>
            <div className="notification-container">
              <div>
                <Select
                  onChange={handleLanguageChange}
                  value={lang}
                  options={[
                    { value: "en", label: "English" },
                    { value: "fr", label: "Français" },
                    { value: "ar", label: "عربي" },
                  ]}
                />
              </div>
              <button
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => setShowNotification(!showNotification)}
              >
                <Badge count={totalNotification()} showZero>
                  <img
                    src="https://media.autochek.africa/file/publicAssets/bell.svg"
                    alt="bell-icon"
                  />
                </Badge>
              </button>

              {showNotification && (
                <NotificationView
                  notificationsResponse={notifications}
                  loadNotification={loadingNotification}
                  activePage={activePage}
                  setActivePage={setActivePage}
                  inAppNotification={inAppNotifications}
                  inAppLoadNotification={InAppLoadNotification}
                  inAppActivePage={inAppActivePage}
                  setInAppActivePage={setInAppActivePage}
                  setShowNotification={setShowNotification}
                />
              )}
            </div>

            <div className="web-logout">
              {isAssumedUser && (
                <button
                  className="text-sm bg-[#ffb619] text-[#30345e] font-semibold rounded-[44px] min-w-[126px] w-auto h-[32px] px-3"
                  onClick={handleRevertUser}
                >
                  {loading ? <Loader variant="secondary" /> : "REVERT USER"}
                </button>
              )}
            </div>

            <button className="hidden xl:block" onClick={handleSignOut}>
              <img
                src="https://media.autochek.africa/file/publicAssets/Vector-Stroke.svg"
                alt="logout-icon"
                width="16px"
                height="16px"
              />
            </button>

            {!openMobile ? (
              <button onClick={showMobileDrawer}>
                <img
                  src="https://media.autochek.africa/file/publicAssets/Vector-Stroke-0.svg"
                  alt="menu-icon"
                  className="menu-mobile"
                />
              </button>
            ) : (
              <button onClick={onCloseMobile}>
                <img
                  src="https://media.autochek.africa/file/publicAssets/Icon-2.svg"
                  alt="close-icon"
                  className="menu-mobile"
                />
              </button>
            )}
          </LogoutContainer>
        </div>
      </StyledContainer>
      <MobileNav openMobile={openMobile} onCloseMobile={onCloseMobile} />
    </>
  );
};

export default Aside;
