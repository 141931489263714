import { useState } from "react";
import CustomerFinancingCard from "@/components/refix/CustomerFinancingCard";
import DealerLoanApplications from "@/components/DealerLoanApplications";
import { DealerFinancingStepper, DealerFinancingLocked } from "@/assets/svgs";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import storage from "@l/storage";
import {
  IDealerSummary,
  IDealerLimit,
  IDealerLimitResult,
} from "@/interface/dealer";
import { Button, notification, message } from "antd";
import styled from "styled-components";
import queryString from "query-string";
import {
  DEALER_FINANCE_COUNTRIES,
  LIMIT_REQUEST_STATUS,
} from "@/utils/finance-status";
import tw from "tailwind-styled-components";
import { Loader } from "./auth/styles/loader";
import { useTranslation } from "react-i18next";

interface IActivePage {
  currentPage: number;
  pageSize: number;
}

const CustomLoading = tw.div`flex items-center justify-center w-full min-h-[70vh] h-auto`;
const CustomButon = styled.div`
  button {
    &:disabled {
      background: #b1b4b5;
    }
  }
`;

const DealerFinancing = () => {
  const user = storage.getUser();
  const navigate = useNavigate();
  const { search } = useLocation();
  const country = storage.getCountry();
  const query: any = queryString.parse(search);
  const queryClient = useQueryClient();
  const [dealerLimit, setDealerLimit] = useState<IDealerLimit | undefined>();
  const [dealerSummary, setDealerSummary] = useState<
    IDealerSummary | undefined
  >();
  const [activePage, setActivePage] = useState<IActivePage>({
    currentPage: 1,
    pageSize: 10,
  });
  const [searchParams] = useSearchParams();
  const dealerId = searchParams.get("dealer_id");
  const [fetchDealerLoans, setFetchDealerLoans]: any = useState([]);
  const [value, setValue] = useState<string | number>("loanApplications");
  const [api, contextHolder] = notification.useNotification();
  const [loanLimitData, setLoanLimitData]: any = useState({});
  const { t } = useTranslation("dealer-gamification");
  const isDealerFinancingEnabled = DEALER_FINANCE_COUNTRIES.includes(country);
  const isAssumedUser = storage.getAssumedUser();
  const isFranchise = storage.utilities.isFranchise();

  const STATUSES = [
    LIMIT_REQUEST_STATUS.LIMIT_AGREEMENT_PENDING_REVIEW,
    LIMIT_REQUEST_STATUS.LIMIT_ACTIVATED,
    LIMIT_REQUEST_STATUS.LIMIT_SIGNED_DOC_UPLOADED,
  ];

  const _STATUSES = [
    LIMIT_REQUEST_STATUS.LIMIT_ASSIGNED,
    LIMIT_REQUEST_STATUS.LIMIT_SIGNED_DOC_UPLOADED,
    LIMIT_REQUEST_STATUS.LIMIT_AGREEMENT_PENDING_REVIEW,
    LIMIT_REQUEST_STATUS.LIMIT_REQUEST_COMPLETED,
    LIMIT_REQUEST_STATUS.LIMIT_REQUEST_NOT_COMPLETED,
  ];

  const hasLimitActivated = dealerLimit?.results?.some(
    (item: any) => item?.status === LIMIT_REQUEST_STATUS.LIMIT_ACTIVATED
  );

  const { isError: isDealerSummaryError } = useQuery({
    queryKey: ["fetch_dealer_summary"],
    queryFn: () => {
      const franchiseId = user?.company?.id;
      return client.get(
        `/v1/franchise/${franchiseId || dealerId}/dealer-tiers/summary`
      );
    },
    onSuccess: (res: any) => {
      const parsedResponseData: IDealerSummary = JSON.parse(res?.data);
      setDealerSummary(parsedResponseData);
    },
    onError: (err: any) => {
      const parsedErr = JSON.parse(err.response.data);
      message.error(parsedErr.message ? parsedErr.message : parsedErr.error);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: dealerLimitLoading, isFetching: dealerLimitFetch } =
    useQuery({
      queryKey: ["fetch_dealer_limit"],
      queryFn: () => {
        return client.get(`/v1/dealer/${user?.company?.id || dealerId}`);
      },
      onSuccess: (res: any) => {
        const parsedResponseData: IDealerLimit = JSON.parse(res?.data);
        setDealerLimit(parsedResponseData);
        queryClient.invalidateQueries({
          queryKey: ["fetch_notifications"],
        });
      },
      retry: false,
      refetchInterval: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    });

  const {
    isLoading: dealerFinancingLoading,
    isFetching: dealerFinancingFetch,
  } = useQuery({
    queryKey: [
      "dealer_finance_fetch",
      query,
      user?.company?.id || dealerId,
      value,
    ],
    queryFn: () => {
      const params = new URLSearchParams({
        current_page: activePage.currentPage.toString(),
        page_size: activePage.pageSize.toString(),
        status: value === "loanApplications" ? "" : "DISBURSED",
        dealer_id: isFranchise ? user?.company?.id : "",
        user_id: dealerId ? user?.id : "",
        // ...query,
      });
      return client.get(`/v1/dealer/loans`, { params });
    },
    onSuccess: (res: any) => {
      const parsedLoans = JSON.parse(res?.data);
      setFetchDealerLoans(parsedLoans);
      queryClient.invalidateQueries({
        queryKey: ["fetch_notifications"],
      });
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useQuery({
    queryKey: ["loan_limit_utility", user?.company?.id || dealerId],
    queryFn: () => {
      return client.get(
        `/v1/dealer/loan-utility/${user?.company?.id || dealerId}`
      );
    },
    onSuccess: (res: any) => {
      const parsedLoans = JSON.parse(res?.data);
      setLoanLimitData(parsedLoans);
    },
    enabled: !!user?.company?.id || !!dealerId,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const resumeApplicationLink = (data: any) => {
    return dealerLimit?.results && dealerLimit?.results?.length > 1
      ? `/dealer/loan-limit?loan_limit=${data?.id}&form_type=enhancement&dealer_id=${dealerId}`
      : `/dealer/loan-limit?loan_limit=${data?.id}&dealer_id=${dealerId}`;
  };

  const toLoanLimitForm = (limit: any) => {
    if (
      !dealerLimitLoading &&
      limit?.status === "limit_request_not_completed"
    ) {
      navigate(resumeApplicationLink(limit));
    } else {
      navigate(`/dealer/loan-limit?form_type=new&dealer_id=${dealerId}`);
    }
  };

  const toCreditLimit = (dealerLimit: any) => {
    navigate(
      `/dealer/credit-limit?limit_id=${dealerLimit?.id}&dealer_id=${dealerId}`
    );
  };

  const isNonOriginatingDealer = isDealerSummaryError;

  const limitInitialState =
    isNonOriginatingDealer || dealerLimit?.results?.length === 0;

  const renderCreditLimitButton = (dealerLimit: any) => {
    return (
      <CustomButon>
        <Button
          onClick={() => toCreditLimit(dealerLimit)}
          className="bg-[#FFB619] py-1 rounded-full border-none flex items-center justify-center min-w-[150px] w-auto px-4 h-[1.895rem]"
        >
          <span className="text-[#30345E] capitalize text-sm lg:text-base font-semibold">
            {t("activate-credit-limit")}
          </span>
        </Button>
      </CustomButon>
    );
  };

  const getApplicationButton = (limit: any) => {
    return (
      <CustomButon>
        <Button
          onClick={() => toLoanLimitForm(limit)}
          disabled={!!isAssumedUser}
          className="bg-[#FFB619] px-3 py-1 rounded-full border-none flex items-center justify-center min-w-[180px] w-auto h-[1.995rem] mt-3 sm:mt-0"
        >
          <span className="text-[#30345E] capitalize text-base font-semibold">
            {limit?.status === LIMIT_REQUEST_STATUS.LIMIT_REQUEST_NOT_COMPLETED
              ? t("resume-application")
              : t("start-application")}
          </span>
        </Button>
      </CustomButon>
    );
  };

  const renderLimitIncreaseButton = (dealerLimit: any) => {
    return (
      <button
        onClick={() =>
          navigate(
            `/dealer/loan-limit?form_type=enhancement&finance_id=${dealerLimit?.id}&dealer_id=${dealerId}`
          )
        }
        disabled={!!isAssumedUser}
        className="bg-[#FFB619] py-1 text-[#30345E] capitalize text-sm lg:text-base font-semibold rounded-full border-none flex items-center justify-center min-w-[150px] w-auto px-4 h-[1.895rem] disabled:text-black/25 disabled:bg-[#b1b4b5] disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed"
      >
        {t("Increase Limit")}
      </button>
    );
  };

  const renderButtonContent = (limit: any) => {
    const {
      LIMIT_ASSIGNED,
      LIMIT_SIGNED_DOC_UPLOADED,
      LIMIT_REQUEST_COMPLETED,
      LIMIT_ACTIVATED,
    } = LIMIT_REQUEST_STATUS;

    const isDeclinedStatus = limit?.loanLimits?.find(
      (item: any) => item.status === "DECLINED"
    );

    if (
      limit?.status === LIMIT_ACTIVATED &&
      dealerLimit?.results?.length === 1
    ) {
      return renderLimitIncreaseButton(limit);
    } else if (
      limit?.status === LIMIT_ASSIGNED ||
      (limit?.status === LIMIT_SIGNED_DOC_UPLOADED &&
        isDeclinedStatus?.actionRequired === "REUPLOAD") ||
      (limit?.status === LIMIT_SIGNED_DOC_UPLOADED &&
        isDeclinedStatus?.actionRequired === "SIGN")
    ) {
      return renderCreditLimitButton(limit);
    } else if (!STATUSES.includes(limit?.status as LIMIT_REQUEST_STATUS)) {
      if (limit?.status === LIMIT_REQUEST_COMPLETED) {
        return "";
      } else {
        return getApplicationButton(limit);
      }
    }
  };

  const renderEligibilityMessage = (): string => {
    if (DEALER_FINANCE_COUNTRIES.includes(country)) {
      return isNonOriginatingDealer
        ? t("you-are-not-eligible")
        : t("you-are-eligible");
    }
    return t("you-are-not-eligible");
  };

  const renderFinancingCard = (elm: any) => {
    return (
      <div>
        <div className="font-bold custom-font-bold text-xl lg:text-2xl lg:text-center text-[#30345E]">
          {t("available-financing-options")}
        </div>
        <div className="grid lg:grid-cols-3 grid-cols-2 grid-rows-auto w-full max-w-full lg:max-w-[90%] my-0 mx-auto lg:gap-x-4 gap-x-2 gap-y-4 mt-4">
          <FinancingOptionsCard
            text={t("import-financing")}
            body={t(
              "worry-less-about-how-to-import-that-dream-car-for-your-customer"
            )}
            link={`/dealer/financing/loan?form_type=new&product_type=import&dealer_id=${dealerId}`}
            dealerLimit={elm}
          />
          <FinancingOptionsCard
            text={t("local-financing")}
            body={t(
              "get-your-customer-that-car-they-handpicked-even-if-you-do-not-own-it"
            )}
            link={`/dealer/financing/loan?form_type=new&product_type=local&dealer_id=${dealerId}`}
            dealerLimit={elm}
          />
          <FinancingOptionsCard
            text={t("duty-financing")}
            body={t("dont-wait-till-you-have-the-funds-to-clear-your-shipment")}
            link={`/dealer/financing/loan?form_type=new&product_type=duty&dealer_id=${dealerId}`}
            dealerLimit={elm}
          />
          {/* <FinancingOptionsCard
            text={t("repo-financing")}
            body={t("explore-our-inventory-of-used-cars")}
            link="/dealer/all-deals"
            dealerLimit={elm}
          /> */}
        </div>
      </div>
    );
  };

  const renderInitialCustomerCard = () => {
    return (
      <CustomerFinancingCard
        key={undefined}
        icon={<DealerFinancingLocked />}
        dealerLimit={undefined}
        dealerSummaryErr={isDealerSummaryError}
        dealerSummary={dealerSummary}
        limitStatuses={STATUSES}
        isDealerFinancingEnabled={isDealerFinancingEnabled}
        loanLimitData={loanLimitData}
        isNonOriginatingDealer={isNonOriginatingDealer}
        api={api}
        resultArr={dealerLimit?.results}
      >
        <div className="px-4 pb-4 pt-1">
          {DEALER_FINANCE_COUNTRIES.includes(country) && (
            <div>
              {!isNonOriginatingDealer && renderButtonContent(undefined)}
            </div>
          )}
        </div>
      </CustomerFinancingCard>
    );
  };

  return (
    <main>
      {contextHolder}

      {dealerLimitFetch || dealerFinancingFetch ? (
        <CustomLoading>
          <Loader variant={"secondary"} />
        </CustomLoading>
      ) : (
        <div>
          <div className="flex items-center justify-between mb-3">
            <div className="font-outfitBold custom-font-bold font-bold text-xl lg:text-2xl text-[#30345E]">
              {dealerId ? dealerSummary?.dealership : t("my-financing")}
            </div>
            <div>
              <p className="font-semibold text-xs lg:text-base text-[#30345E]">
                {renderEligibilityMessage()}
              </p>
            </div>
          </div>

          {isDealerFinancingEnabled ? (
            <div>
              {limitInitialState ? (
                renderInitialCustomerCard()
              ) : (
                <div className="flex flex-row gap-4">
                  {dealerLimit?.results?.map((elem: IDealerLimitResult) => (
                    <CustomerFinancingCard
                      key={elem?.id}
                      icon={<DealerFinancingLocked />}
                      dealerLimit={elem}
                      dealerSummaryErr={isDealerSummaryError}
                      dealerSummary={dealerSummary}
                      limitStatuses={STATUSES}
                      isDealerFinancingEnabled={isDealerFinancingEnabled}
                      loanLimitData={loanLimitData}
                      isNonOriginatingDealer={isNonOriginatingDealer}
                      api={api}
                      resultArr={dealerLimit?.results}
                    >
                      <div className="px-4 pb-4 pt-1">
                        {DEALER_FINANCE_COUNTRIES.includes(country) && (
                          <div>
                            {!isNonOriginatingDealer &&
                              renderButtonContent(elem)}
                          </div>
                        )}
                      </div>
                    </CustomerFinancingCard>
                  ))}
                </div>
              )}
            </div>
          ) : (
            renderInitialCustomerCard()
          )}

          <div className="mt-8">
            {isDealerFinancingEnabled ? (
              <>
                {limitInitialState ? (
                  <FinancingStep1 />
                ) : (
                  <>
                    {dealerLimit?.results &&
                    dealerLimit?.results?.length > 1 ? (
                      <>
                        {dealerLimit?.results?.map(
                          (elm: IDealerLimitResult) => (
                            <div key={elm?.id}>
                              {_STATUSES.includes(
                                elm?.status as LIMIT_REQUEST_STATUS
                              ) &&
                              elm?.status ===
                                LIMIT_REQUEST_STATUS.LIMIT_ACTIVATED ? (
                                <FinancingStep2 />
                              ) : (
                                elm?.status ===
                                  LIMIT_REQUEST_STATUS.LIMIT_ACTIVATED &&
                                renderFinancingCard(elm)
                              )}
                            </div>
                          )
                        )}
                      </>
                    ) : (
                      <>
                        {dealerLimit?.results?.map(
                          (elm: IDealerLimitResult) => (
                            <div key={elm?.id}>
                              {_STATUSES.includes(
                                elm?.status as LIMIT_REQUEST_STATUS
                              ) ? (
                                <FinancingStep2 />
                              ) : (
                                elm?.status ===
                                  LIMIT_REQUEST_STATUS.LIMIT_ACTIVATED &&
                                renderFinancingCard(elm)
                              )}
                            </div>
                          )
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <FinancingStep1 />
            )}
          </div>

          {isDealerFinancingEnabled && (
            <div className="pt-8 lg:pt-12">
              {fetchDealerLoans && hasLimitActivated && (
                <DealerLoanApplications
                  activePage={activePage}
                  setActivePage={setActivePage}
                  query={query}
                  fetchDealerLoans={fetchDealerLoans}
                  dealerFinancingLoading={dealerFinancingLoading}
                  value={value}
                  setValue={setValue}
                />
              )}
            </div>
          )}
        </div>
      )}
    </main>
  );
};

export default DealerFinancing;

const FinancingStep1 = () => {
  const { t } = useTranslation("dealer-gamification");

  return (
    <div>
      <div>
        <h4 className="font-outfitBold custom-font-bold font-bold text-left lg:text-center text-base lg:text-2xl text-[#30345E]">
          {t("how-to-qualify-for-financing")}
        </h4>
        <p className="font-medium text-left lg:text-center text-sm lg:text-base text-[#6B7280]">
          {t("you-need-to-be-on-any-of-these-dealership-levels")}
        </p>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-3 mt-5">
        <StageCard
          medal_url={`https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-medals/Bronze.png?updatedAt=1700813945032`}
          dealer_stage={t("bronze-stage")}
          stage_desc={t("sell-one-200")}
        />
        <StageCard
          medal_url={`https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-medals/Medals.png?updatedAt=1700813944875`}
          dealer_stage={t("silver-stage")}
          stage_desc={t("sell-three-600")}
        />
        <StageCard
          medal_url={`https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-medals/Medals-1.png?updatedAt=1700813944709`}
          dealer_stage={t("gold-stage")}
          stage_desc={t("sell-six-1200")}
        />
        <StageCard
          medal_url={`https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-medals/Medals-2.png?updatedAt=1700813944845`}
          dealer_stage={t("platinum-stage")}
          stage_desc={t("sell-twelve-2400")}
        />
      </div>
    </div>
  );
};

const FinancingStep2 = () => {
  const { t } = useTranslation("dealer-gamification");

  const why_loan_limit = [
    {
      id: 1,
      heading: t("fill-out-the-loan-limit-approval-form"),
      text: t("this-will-help-us-determine-your-loan-limit"),
    },
    {
      id: 2,
      heading: t("sign-master-agreement-contract"),
      text: t("you-will-need-to-sign-the-loan-agreement-contracts"),
    },
    {
      id: 3,
      heading: t("start-applying-for-loans"),
      text: t(
        "once-we-have-verified-your-collaterals-you-have-full-access-to-the-loan-facilities"
      ),
    },
  ];

  return (
    <div className="px-4">
      <div>
        <p className="text-xl lg:text-2xl text-left lg:text-center font-bold custom-font-bold text-[#30345E]">
          {t("why-do-i-need-to-have-a-loan-limit")}?
        </p>
        <p className="text-sm lg:text-base text-left lg:text-center text-[#30345E] leading-5 pt-2 max-w-lg mx-auto">
          {t("your-loan-limit-helps")}
        </p>
      </div>
      <div className="pt-6">
        <p className="text-xl lg:text-2xl font-bold text-left lg:text-center custom-font-bold text-[#30345E]">
          {t("how-it-works")}
        </p>
        <div className="pt-4">
          <ul className="grid grid-cols-1 lg:grid-cols-3 grid-rows-auto gap-x-5">
            {why_loan_limit.map((item) => {
              return (
                <li
                  key={item.id}
                  className="border-b lg:border lg:border-solid lg:border-[#e5e7eb] py-3 bg-transparent lg:bg-white p-0 lg:p-4 rounded-lg"
                >
                  <div className="flex flex-row md:flex-row lg:flex-col space-y-0 lg:space-y-3 space-x-3 lg:space-x-0">
                    <div>
                      <DealerFinancingStepper />
                    </div>
                    <div className="max-w-md">
                      <div className="leading-relaxed font-bold text-[#30345E] text-lg custom-font-bold tracking-[0.01rem]">
                        {item.heading}
                      </div>
                      <div className="text-sm lg:text-base text-[#30345E]">
                        {item.text}
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

const StageCard = ({
  medal_url,
  dealer_stage,
  stage_desc,
}: {
  medal_url: string;
  dealer_stage: string;
  stage_desc: string;
}) => {
  return (
    <div className="bg-white rounded-lg w-full p-4 min-h-[8.9375rem] h-auto md:h-[8rem] border border-solid border-[#e5e7eb]">
      <div>
        <img
          src={medal_url}
          className=" w-[2.875rem] h-[2.875rem]"
          alt="Medal"
        />
      </div>
      <div className="pt-2">
        <p className="uppercase font-outfitBold custom-font-bold font-bold text-[#30345E] text-sm">
          {dealer_stage}
        </p>
        <p className="text-sm text-[#6B7280]">{stage_desc}</p>
      </div>
    </div>
  );
};

const FinancingOptionsCard = ({
  text,
  body,
  link,
  dealerLimit,
}: {
  text: string;
  body: string;
  link: string;
  dealerLimit: any;
}) => {
  const navigate = useNavigate();
  const isAssumedUser = storage.getAssumedUser();

  return (
    <div className="bg-white rounded-lg w-full p-4 lg:h-[11rem] min-h-[13rem] h-auto border border-solid border-[#e5e7eb]">
      <div className="lg:mb-4 mb-2">
        <p className="capitalize font-bold text-[#30345E] text-base lg:text-lg custom-font-bold">
          {text}
        </p>
        <p className="text-sm lg:text-base text-[#30345E] leading-tight pt-2">
          {body}
        </p>
      </div>
      <button
        disabled={
          dealerLimit?.status !== LIMIT_REQUEST_STATUS.LIMIT_ACTIVATED ||
          !!isAssumedUser
        }
        onClick={() => navigate(link)}
        className="flex items-center justify-center px-2 py-1 text-[#30345E] border border-solid border-[#30345E] rounded-full disabled:text-black/25 disabled:bg-black/5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed"
      >
        <span className="text-sm font-bold tracking-[0.01563rem] capitalize">
          Apply now
        </span>
      </button>
    </div>
  );
};
