import { useEffect, useState, Fragment } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Autocomplete from "@mui/material/Autocomplete";
import { NumericFormat } from "react-number-format";
import storage from "@/lib/storage";
import { getCountryFlag } from "@/utils/getCountryFlag";
import { InputNumber } from "antd";
import { TelInputContainer } from "@/pages/auth/styles/sign-in";
import { getCountryTelCode } from "@/utils/getCountryTelCode";
import Checkbox from "@mui/material/Checkbox";
import { convertStringToBoolean } from "@/utils/stringToBoolean";
import { useTranslation } from "react-i18next";
import { IDirectorField } from "@/types";
import { v4 as generateUuid } from "uuid";
import LoadingButton from "@mui/lab/LoadingButton";

const SubSectionTitle = ({ subsection }: any) =>
  subsection ? (
    <Grid item xs={12} sm={12} md={12}>
      <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
        {subsection}
      </Typography>
    </Grid>
  ) : null;

let toggleDirectorFieldVisibility: boolean;

export const Input = ({
  formData,
  required,
  type,
  label,
  placeholder,
  name,
  when,
  options,
  onChange,
  selectOptions,
  fileType,
  value,
  onBlur,
  visibility,
  fullWidth,
  autoComplete,
  subsection,
  addDirector,
  directorCount,
  hideSubsection = false,
}: any) => {
  const country = storage.getCountry();
  const { t } = useTranslation("loan-form");
  const [showPassword, setShowPassword] = useState(false);
  const isFranchise = storage.utilities.isFranchise();
  const isAccountManager = storage.utilities.isAccountManager();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const hasUrl = (text: string) => {
    const urlRegex = /(https?:\/\/[^\s)]+)/g;
    return urlRegex.test(text);
  };

  const renderUrls = (text: string, isRequired: boolean) => {
    const urlRegex = /(https?:\/\/[^\s)]+)/g;
    const replacedUrl = text.replace(
      urlRegex,
      (url) =>
        `<a class="text-[#30345e] font-semibold" href="${url}" target="_blank">${url}</a>`
    );

    return isRequired ? `${replacedUrl} *` : `${replacedUrl}`;
  };

  const renderReferrerAsterisk = () => {
    return name === "referrerSource" && isAccountManager ? "*" : "";
  };

  const generateDirectorsArray = (
    noOfDirectorsSelected: number,
    startIndex: number
  ): IDirectorField[] => {
    const baseDirObjects = [
      {
        options: [],
        fields: [],
        actions: [],
        when: [],
        label: "Director’s BVN",
        name: "directorBVN1",
        type: "number",
        required: false,
        length: 11,
        minValue: 0,
        visibility: false,
        subsection: "Director Details 1",
        id: "myProfession_description_Business owner_directorBVN1",
      },
      {
        options: [],
        fields: [],
        actions: [],
        when: [],
        label: "Director's first name",
        name: "directorFirstname1",
        type: "text",
        required: false,
        visibility: false,
        subsection: "Director Details 1",
        id: "myProfession_description_Business owner_directorFirstname1",
      },
      {
        options: [],
        fields: [],
        actions: [],
        when: [],
        label: "Director’s last name",
        name: "directorLastname1",
        type: "text",
        required: false,
        visibility: false,
        subsection: "Director Details 1",
        id: "myProfession_description_Business owner_directorLastname1",
      },
    ];

    const generatedArray: IDirectorField[] = [];

    for (let i = startIndex; i <= noOfDirectorsSelected; i++) {
      const newItems = baseDirObjects.map((item) => {
        const newItem: IDirectorField = {
          ...item,
          visibility: true,
        };
        Object.keys(newItem).forEach((key) => {
          if (typeof newItem[key as keyof IDirectorField] === "string") {
            //@ts-ignore
            newItem[key as keyof IDirectorField] = (
              newItem[key as keyof IDirectorField] as string
            ).replace("1", i.toString());
          }
        });
        return newItem;
      });
      generatedArray.push(...newItems);
    }

    return generatedArray;
  };

  useEffect(() => {
    const handleArrowKeys = (e: any) => {
      if (e.key.startsWith("Arrow")) {
        e.preventDefault();
      }
      if (
        !(
          (e.keyCode > 95 && e.keyCode < 106) ||
          (e.keyCode > 47 && e.keyCode < 58) ||
          e.keyCode === 8
        )
      ) {
        return false;
      }
      if (e.which === 38 || e.which === 40) {
        e.preventDefault();
      }
    };

    const handleArrowScroll = (e: any) => {
      e.target.blur();
    };

    const handlePreventNegativeInput = (e: any) => {
      if (e.code === "Minus") {
        e.preventDefault();
      }
    };

    const numberWithInputs = document.querySelectorAll("input[type=number]");

    numberWithInputs.forEach((input) => {
      input.addEventListener("keydown", handleArrowKeys);
      input.addEventListener("mousewheel", handleArrowScroll);
      input.addEventListener("keypress", handlePreventNegativeInput);
    });

    return () => {
      numberWithInputs.forEach((input) => {
        input.removeEventListener("keydown", handleArrowKeys);
        input.removeEventListener("mousewheel", handleArrowScroll);
        input.removeEventListener("keypress", handlePreventNegativeInput);
      });
    };
  }, []);

  if (
    name === "source" ||
    name === "referrerDataId" ||
    (name === "referrerSource" && isFranchise)
  ) {
    return null;
  }

  let input;

  if (type === "radio") {
    const nestedFields = when?.find(
      ({ value }: any) => value === formData?.[name]?.value
    )?.fields;

    const directorNumSelectField = nestedFields?.find(
      (field: any) => field.name === "noBusinessDirectors"
    );

    if (directorNumSelectField) {
      toggleDirectorFieldVisibility = true;
    }

    return visibility ? (
      <>
        <SubSectionTitle subsection={t(subsection)} />
        <Grid item xs={12} sm={12} md={fullWidth ? 12 : 6}>
          {label && (
            <Typography
              variant="body2"
              gutterBottom
              className="formLabel"
              fontWeight="500"
              sx={{
                whiteSpace: "pre-line",
              }}
            >
              {hasUrl(label) ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: renderUrls(label, required),
                  }}
                />
              ) : (
                <>{required ? `${t(label)} *` : t(label)}</>
              )}
            </Typography>
          )}

          <FormControl required={required}>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              // onChange={onChange}
              onChange={(e: any) => {
                onChange({ ...e, label: t(label) });
              }}
              value={value}
            >
              <Stack
                direction="row"
                alignItems="center"
                sx={{ cursor: "pointer" }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                }}
              >
                {options.map((option: any, index: number) => (
                  <FormControlLabel
                    key={option.label}
                    id={name}
                    name={name}
                    value={option?.value ?? option}
                    control={<Radio required={required} />}
                    label={option?.label ?? t(option)}
                    aria-label={label}
                    onBlur={onBlur}
                  />
                ))}
              </Stack>
            </RadioGroup>
          </FormControl>
        </Grid>

        {nestedFields?.map((field: any, index: number) => (
          <Input
            key={field.name}
            formData={formData}
            selectOptions={selectOptions}
            required={field.required}
            type={field.type}
            label={field.label}
            placeholder={field.label}
            name={field.name}
            when={field.when}
            actions={field.actions}
            fields={field.fields}
            onBlur={onBlur}
            options={
              field.options?.length
                ? field.options
                : selectOptions[field.name] || null
            }
            value={formData[field.name]?.value ?? ""}
            // onChange={onChange}
            onChange={(e: any) => {
              onChange({ ...e, label: t(field.label) });
            }}
            visibility={field.visibility}
            fullWidth={field.fullWidth}
            autoComplete={!field.options?.length}
            subsection={
              field.subsection === nestedFields[index - 1]?.subsection
                ? null
                : field.subsection
            }
            addDirector={addDirector}
            directorCount={directorCount}
            hideSubsection={hideSubsection}
          />
        ))}
      </>
    ) : null;
  } else if (type === "selection" && autoComplete) {
    const nestedFields = when?.find(
      ({ value }: any) => value === formData[name]?.value
    )?.fields;

    const autocompleteOptions =
      options?.map((x: any) => ({
        label: x.label || x.name || x,
        value: x.value || x.name || x,
      })) ?? [];

    const currentValue = autocompleteOptions.find(
      (item: any) => item.value === value
    );

    return visibility ? (
      <>
        <SubSectionTitle subsection={t(subsection)} />
        <Grid item xs={12} sm={12} md={fullWidth ? 12 : 6}>
          {label && (
            <Typography
              variant="body2"
              gutterBottom
              className="formLabel"
              fontWeight="500"
            >
              {required ? `${t(label)} *` : t(label)}
            </Typography>
          )}

          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              aria-label={label}
              // name={name}
              defaultValue={currentValue}
              value={autocompleteOptions.length ? currentValue : null}
              options={autocompleteOptions}
              onChange={(e, val) => {
                const data = {
                  target: {
                    name,
                    value: val?.value,
                    label: label,
                  },
                };
                onChange(data);
              }}
              onBlur={onBlur}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} required={required} />
              )}
            />
          </FormControl>
        </Grid>

        {nestedFields?.map((field: any, index: number) => (
          <Input
            key={field.name}
            required={field.required}
            type={field.type}
            label={t(field.label)}
            placeholder={t(field.label)}
            name={field.name}
            when={field.when}
            actions={field.actions}
            onBlur={onBlur}
            fields={field.fields}
            options={
              field.options?.length
                ? field.options
                : selectOptions[field.name] || null
            }
            // onChange={onChange}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChange({ ...e, label: t(label) });
            }}
            value={formData[field.name]?.value ?? ""}
            visibility={field.visibility}
            fullWidth={field.fullWidth}
            autoComplete={!field.options.length}
            subsection={
              field.subsection === nestedFields[index - 1]?.subsection
                ? null
                : field.subsection
            }
          />
        ))}
      </>
    ) : null;
  } else if (type === "selection") {
    const nestedFields = when?.find(
      ({ value }: any) => value === formData[name]?.value
    )?.fields;

    if (name === "noBusinessDirectors") {
      toggleDirectorFieldVisibility = true;
    }

    return visibility ? (
      <>
        <SubSectionTitle subsection={t(subsection)} />
        <Grid item xs={12} sm={12} md={fullWidth ? 12 : 6}>
          {label && (
            <Typography
              variant="body2"
              gutterBottom
              className="formLabel"
              fontWeight="500"
            >
              {required
                ? `${t(label)} *`
                : `${t(label)} ${renderReferrerAsterisk()}`}
            </Typography>
          )}

          <FormControl fullWidth>
            <Select
              multiline
              name={name}
              onChange={(e) =>
                onChange({ ...e, target: { ...e.target, label: t(label) } })
              }
              aria-label={label}
              required={required}
              onBlur={onBlur}
              value={t(value)}
              displayEmpty
              renderValue={(value) => value || `${t("Select")} ${t(label)}`}
            >
              {options?.map((x: any, index: number) => (
                <MenuItem key={x.name} value={x.value || x.id || x}>
                  {t(x.label) || t(x.name) || t(x)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {nestedFields?.map((field: any, index: number) => (
          <Input
            key={field.name}
            required={field.required}
            type={field.type}
            label={field.label}
            placeholder={field.label}
            name={field.name}
            when={field.when}
            actions={field.actions}
            onBlur={onBlur}
            fields={field.fields}
            options={
              field.options?.length
                ? field.options
                : selectOptions[field.id] || selectOptions[field.name] || null
            }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChange({ ...e, label: t(label) });
            }}
            value={formData[field.name]?.value ?? ""}
            visibility={field.visibility}
            fullWidth={field.fullWidth}
            autoComplete={!field.options?.length}
            subsection={
              field.subsection === nestedFields[index - 1]?.subsection
                ? null
                : field.subsection
            }
          />
        ))}
      </>
    ) : null;
  } else if (type === "tel") {
    input = (
      <>
        {label && (
          <Typography
            variant="body2"
            gutterBottom
            className="formLabel"
            fontWeight="500"
          >
            {required ? `${t(label)} *` : t(label)}
          </Typography>
        )}
        <TelInputContainer>
          <InputNumber
            addonBefore={
              <img
                src={getCountryFlag(country)}
                alt="flag"
                style={{ width: "32px", height: "32px" }}
              />
            }
            prefix={getCountryTelCode(country)}
            style={{ width: "100%" }}
            onChange={(val: any) => {
              const data = {
                target: {
                  name,
                  value: `${getCountryTelCode(country)}${val}`,
                  type,
                  label,
                },
              };
              onChange(data);
            }}
            required={required}
            value={
              value
                ? value?.substring(getCountryTelCode(country)?.length ?? 3)
                : ""
            }
            onBlur={onBlur}
            min={0}
            keyboard={false}
          />
        </TelInputContainer>
      </>
    );
  } else if (type === "file") {
    input = (
      <>
        {label && (
          <Typography
            variant="body2"
            gutterBottom
            className="formLabel"
            fontWeight="500"
          >
            {required ? `${t(label)} *` : t(label)}
          </Typography>
        )}

        <TextField
          fullWidth
          placeholder={placeholder}
          name={name}
          type={type}
          required={required}
          onChange={onChange}
          onBlur={onBlur}
          aria-label={label}
          inputProps={{ accept: fileType }}
        />
      </>
    );
  } else if (type === "amount") {
    input = (
      <>
        {label && (
          <Typography
            variant="body2"
            gutterBottom
            className="formLabel"
            fontWeight="500"
          >
            {required ? `${t(label)} *` : t(label)}
          </Typography>
        )}

        <NumericFormat
          fullWidth
          placeholder={t(placeholder)}
          name={name}
          onBlur={onBlur}
          required={required}
          aria-label={label}
          thousandSeparator=","
          allowNegative={false}
          value={value}
          onChange={(e) => {
            onChange({
              ...e,
              target: { ...e.target, name, type, label: t(label) },
            });
          }}
          customInput={TextField}
        />
      </>
    );
  } else if (type === "password") {
    input = (
      <>
        {label && (
          <Typography
            variant="body2"
            gutterBottom
            className="formLabel"
            fontWeight="500"
          >
            {required ? `${t(label)} *` : t(label)}
          </Typography>
        )}

        <TextField
          fullWidth
          placeholder={t(placeholder)}
          name={name}
          type={showPassword ? "text" : type}
          required={required}
          onChange={(e) => {
            onChange({ ...e, target: { ...e.target, name, type, label } });
          }}
          onBlur={onBlur}
          aria-label={label}
          value={value}
          InputProps={{
            endAdornment: (
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            ),
          }}
        />
      </>
    );
  } else if (type === "checkbox") {
    input = (
      <Stack direction="row" alignItems="center">
        <Typography
          variant="body2"
          gutterBottom
          className="formLabel"
          fontWeight="500"
        >
          {required ? `${t(label)} *` : t(label)}
        </Typography>
        <Checkbox
          size="small"
          name={name}
          required={required}
          defaultChecked={convertStringToBoolean(value)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const { name, type, checked } = e.target;
            const formAttribute = {
              target: { name, type, value: checked, label },
            };
            onChange(formAttribute);
          }}
          onBlur={onBlur}
        />
      </Stack>
    );
  } else if (type === "message") {
    input = (
      <Stack direction="row" alignItems="center">
        <Typography
          variant="body2"
          gutterBottom
          className="formLabel"
          fontWeight="500"
        >
          {required ? `${t(label)} *` : t(label)}
        </Typography>
      </Stack>
    );
  } else if (
    formData?.["noBusinessDirectors"]?.value &&
    name.includes("directorBVN1")
  ) {
    let directorFields: IDirectorField[] = [];

    if (formData?.["isCustomerDirector"]?.value === "No") {
      directorFields = generateDirectorsArray(
        formData?.["noBusinessDirectors"]?.value,
        1
      );
    } else if (formData?.["noBusinessDirectors"]?.value > 1) {
      directorFields = generateDirectorsArray(
        Number(formData?.["noBusinessDirectors"]?.value),
        2
      );
    }

    const groupedData = directorFields.reduce<Record<string, any>>(
      (acc, item) => {
        acc[item.subsection] = acc[item.subsection] || [];
        acc[item.subsection].push(item);
        return acc;
      },
      {}
    );

    return (
      <>
        {Object.entries(groupedData).map(([subsection, items]) => (
          <Fragment key={subsection}>
            <SubSectionTitle subsection={subsection} />
            {items.map((field: any) => (
              <Input
                key={generateUuid}
                required={field.required}
                type={field.type}
                label={field.label}
                placeholder={field.label}
                name={field.name}
                when={field.when}
                actions={field.actions}
                onBlur={onBlur}
                fields={field.fields}
                options={
                  field.options?.length
                    ? field.options
                    : selectOptions[field.id] || null
                }
                onChange={(e: any) => {
                  onChange({ ...e, label: t(label) });
                }}
                value={formData[field.name]?.value ?? ""}
                visibility={field.visibility}
                fullWidth={field.fullWidth}
                autoComplete={!field.options?.length}
                subsection={null}
              />
            ))}
          </Fragment>
        ))}
      </>
    );
  } else {
    const eighteenYearsAgo = new Date(
      new Date().setFullYear(new Date().getFullYear() - 18)
    )
      .toISOString()
      .split("T")[0];
    input = (
      <>
        {label && (
          <Typography
            variant="body2"
            gutterBottom
            className="formLabel"
            fontWeight="500"
          >
            {required ? `${t(label)} *` : t(label)}
          </Typography>
        )}

        <TextField
          fullWidth
          placeholder={t(placeholder)}
          name={name}
          type={type}
          required={required}
          onChange={(e) => {
            if (name === "vehicleYearModel") {
              const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
              if (value.length <= 4) {
                onChange({
                  ...e,
                  target: { ...e.target, name, type, label: t(label) },
                });
              }
            } else {
              onChange({
                ...e,
                target: { ...e.target, name, type, label: t(label) },
              });
            }
          }}
          onBlur={onBlur}
          aria-label={label}
          value={value}
          InputProps={{
            inputProps: {
              max: eighteenYearsAgo,
            },
          }}
        />
      </>
    );
  }

  const showNextDirector = () => {
    const isLastTwoDirectors =
      name.endsWith(directorCount) || name.endsWith(directorCount - 1);
    return isLastTwoDirectors && !visibility;
  };

  return visibility ||
    (!toggleDirectorFieldVisibility && showNextDirector()) ? (
    <>
      {!hideSubsection && <SubSectionTitle subsection={t(subsection)} />}
      <Grid item xs={12} sm={12} md={fullWidth ? 12 : 6}>
        {input}
      </Grid>

      {directorCount < 3 && name?.endsWith(`Lastname${directorCount}`) && (
        <Grid item xs={12} sm={12} md={12}>
          <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
            <LoadingButton
              variant="contained"
              type="button"
              fullWidth={false}
              color="primary"
              onClick={addDirector}
            >
              {t("Add more")}
            </LoadingButton>
          </Typography>
        </Grid>
      )}
    </>
  ) : null;
};

const InputGroup = ({
  showLabel,
  label,
  fields,
  formData,
  handleInputChange,
  selectOptions,
  onBlur = () => {},
  addDirector,
  directorCount,
  hideSubsection,
}: any) => {
  const { t } = useTranslation("loan-form");

  return (
    <>
      {showLabel ? (
        <Typography variant="h6" fontWeight="700" textAlign="center">
          {t(label)}
        </Typography>
      ) : null}

      <Box>
        <Grid container spacing={2}>
          {fields?.map((field: any, index: any) =>
            field.channel && field.channel !== "dealerplus" ? (
              <></>
            ) : (
              <Input
                formData={formData}
                key={field.name}
                onBlur={onBlur}
                required={field.required}
                type={field.type}
                channel={field.channel}
                label={t(field.label)}
                placeholder={t(field.label)}
                fileType={field.fileType}
                name={field.name}
                when={field.when}
                options={
                  field.options?.length
                    ? field.options
                    : selectOptions[field.name] ||
                      selectOptions[field.id] ||
                      null
                }
                onChange={handleInputChange}
                value={formData[field.name]?.value || field.defaultValue}
                selectOptions={selectOptions}
                visibility={field.visibility}
                fullWidth={field.fullWidth}
                autoComplete={!field.options.length}
                subsection={
                  field.subsection === fields[index - 1]?.subsection
                    ? null
                    : field.subsection
                }
                addDirector={addDirector}
                directorCount={directorCount}
                hideSubsection={hideSubsection}
              />
            )
          )}
        </Grid>
      </Box>
    </>
  );
};

export default InputGroup;
